var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"captcha-char-tables","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":"Special Character Table"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"hide-default-footer":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.base64",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.base64}})]}},{key:"item.base64_remove",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.base64_remove}})]}},{key:"item.char",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"autocomplete":false,"autofocus":_vm.isAutoFocus(_vm.items, item)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.updateStatus(item)}},model:{value:(item.char),callback:function ($$v) {_vm.$set(item, "char", $$v)},expression:"item.char"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary darken-1"},on:{"click":function($event){return _vm.updateStatus(item)}}},[_vm._v(" "+_vm._s(_vm.$t("update"))+" ")])]}}])}),_c('v-pagination',{attrs:{"length":_vm.pageCount,"total-visible":20},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }