<template>
  <v-container id="captcha-char-tables" fluid tag="section">
    <base-material-card
      icon="mdi-clipboard-text"
      title="Special Character Table"
      class="px-5 py-3"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        :page.sync="page"
        :items-per-page="itemsPerPage"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <template v-slot:item.base64="{ item }">
          <img v-bind:src="item.base64" />
        </template>
        <template v-slot:item.base64_remove="{ item }">
          <img v-bind:src="item.base64_remove" />
        </template>
        <template v-slot:item.char="{ item }">
          <v-text-field v-model="item.char"></v-text-field>
        </template>
        <template v-slot:item.char="{ item }">
          <v-text-field
            :autocomplete="false"
            :autofocus="isAutoFocus(items, item)"
            v-model="item.char"
            @keyup.enter="updateStatus(item)"
          ></v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn color="primary darken-1" @click="updateStatus(item)">
            {{ $t("update") }}
          </v-btn>
        </template>
        <!-- <template v-slot:item.char="{ item }">
          <v-edit-dialog @save="updateStatus(item)" large>
            {{ item.char }}
            <template v-slot:input>
              <v-text-field
                v-model="item.char"
                label="Edit"
                single-line
                autofocus
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template> -->
      </v-data-table>
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="20"
      ></v-pagination>
    </base-material-card>
  </v-container>
</template>

<script>
import { systemOption } from "@/definition.js";
import { mapState } from "vuex";
export default {
  data: (vm) => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 10,
    items: [],
    totalItems: 0,
    loading: false,
    options: {},
    headers: [
      {
        sortable: false,
        text: vm.$i18n.t("id"),
        value: "id",
      },
      {
        sortable: false,
        text: vm.$i18n.t("base64"),
        value: "base64",
        width: "30%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("base64_remove"),
        value: "base64_remove",
        width: "30%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("char"),
        value: "char",
        width: "15%",
      },
      {
        sortable: false,
        text: vm.$i18n.t("actions"),
        value: "actions",
        width: "10%",
      },
    ],
    systemOption: systemOption,
  }),
  computed: {
    ...mapState(["token"]),
  },
  watch: {
    options: {
      handler() {
        this.getApi();
      },
    },
  },
  mounted() {
    this.$options.sockets.onmessage = this.receiveSocketData;
  },
  methods: {
    isAutoFocus(items, item) {
      console.log(item)
      if (items.indexOf(item) == 0) {
        console.log("t");
        return true;
      }
      console.log("f");
      return false;
    },
    receiveSocketData(message) {
      let info = JSON.parse(message.data);
      console.log("Get the info", info);
      // this.getApi();
      this.totalItems += 1;
      this.items.push(info);
    },
    getApi() {
      this.loading = true;
      const currentObj = this;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const url = process.env.VUE_APP_SERVER_URL + "/system/captchas/";
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
        params: {
          page: page,
          page_size: itemsPerPage,
        },
      };
      config.params["char__isnull"] = true;

      this.axios
        .get(url, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.setResult(response.data);
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        })
        .finally(() => {
          currentObj.loading = false;
        });
    },
    patchApi(item) {
      // create hn account
      console.log("create spider");
      const currentObj = this;
      let id = item["id"];
      const url =
        process.env.VUE_APP_SERVER_URL + "/system/captchas/" + id + "/";

      let jsonData = {
        char: item["char"],
      };
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + this.token,
        },
      };

      this.axios
        .patch(url, jsonData, config)
        .then(function (response) {
          console.log(response.data);
          currentObj.getApi();
        })
        .catch(function (error) {
          console.log(error);
          console.log(error.response);
        });
    },
    setResult(data) {
      this.totalItems = data.count;
      this.items = data.results;
    },

    getPartData(item) {
      if (item != null) {
        let value = item.substr(0, 50);
        return value;
      }
      return item;
    },
    updateStatus(item) {
      if (item != null && item.char != null && item.char.length > 0) {
        this.patchApi(item);
      } else {
        console.log("nothing.");
        alert("No char");
      }
    },
  },
};
</script>
